import { Box, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { renderError } from "../../../../../utils/constants/forms";
import { formatPhoneNumber } from "../../../../../utils/constants/utils";

export default function PhoneNumberRenderer({ field, properties, dataView }) {
  const {
    register,
    formState: { errors, isSubmitted },
    setValue,
    trigger,
  } = useFormContext();

  const handlePhoneNumberChange = (event) => {
    setValue(field.fieldId, formatPhoneNumber(event.target.value));
    trigger(field.fieldId);
  };

  useEffect(() => {
    if (isSubmitted) trigger(field.fieldId);
  }, [field.isValid]);

  return (
    <Box padding={1}>
      <TextField
        fullWidth
        error={!!errors[field.fieldId]}
        label={field.label}
        key={field.fieldId}
        defaultValue={field.response || field.existing_data || ""}
        InputLabelProps={{
          required: properties.isRequired,
          shrink: properties.shrink || undefined,
        }}
        helperText={properties.supportingText}
        InputProps={{ readOnly: dataView }}
        {...register(field.fieldId, {
          onChange: (e) => {
            if (properties.isMasked) handlePhoneNumberChange(e);
          },
          required: properties.isRequired && "This field is required.",
          pattern: {
            value: properties.isMasked ? /^\(\d{3}\) \d{3}-\d{4}$/ : "",
            message: "Invalid phone number.",
          },
          validate: field.isValid
            ? () => true
            : () => properties.validityMessage || "Invalid input",
        })}
      />
      {errors[field.fieldId] && renderError(errors[field.fieldId].message)}
    </Box>
  );
}
