import { Box, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { renderError } from "../../../../../utils/constants/forms";

export default function EmailAddressRenderer({ field, properties, dataView }) {
  const {
    register,
    formState: { errors, isSubmitted },
    trigger,
  } = useFormContext();

  useEffect(() => {
    if (isSubmitted) trigger(field.fieldId);
  }, [field.isValid]);
  return (
    <Box padding={1}>
      <TextField
        fullWidth
        error={!!errors[field.fieldId]}
        label={field.label}
        key={field.fieldId}
        defaultValue={field.response || field.existing_data || ""}
        InputLabelProps={{
          required: properties.isRequired,
          shrink: properties.shrink || undefined,
        }}
        helperText={properties.supportingText}
        InputProps={{ readOnly: dataView }}
        {...register(field.fieldId, {
          required: properties.isRequired && "This field is required.",
          validate: field.isValid
            ? () => true
            : () => properties.validityMessage || "Invalid input",
        })}
      />
      {errors[field.fieldId] && renderError(errors[field.fieldId].message)}
    </Box>
  );
}
