import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { ArrowRightAlt, ExpandMore, Segment } from "@mui/icons-material";
import { useDrag } from "react-dnd";
import { fieldStyle } from "../../FormBuilder.style";
import customFieldsService from "../../../../../../service/customFieldsService";
import { FormBuilderContext } from "../../FormBuilderContext";
import { generateGUID } from "../../../../../../utils/constants/utils";
import { requiredMessage } from "../../../../../../utils/constants/forms";

export default function FieldTypes() {
  const formBuilderContext = useContext(FormBuilderContext);
  const [customStudentProfileFields, setCustomStudentProfileFields] = useState(
    []
  );
  const [customStudentMedicalFields, setCustomStudentMedicalFields] = useState(
    []
  );
  const [customGuardianFields, setCustomGuardianFields] = useState([]);

  const [accordionState, setAccordionState] = useState("");

  const customFieldsArray = [
    {
      type: "short-text",
      label: "Short Answer",
      isCustomField: true,
    },
    {
      type: "long-text",
      label: "Long Answer",
      isCustomField: true,
    },
    {
      type: "email-address",
      label: "Email Address",
      isCustomField: true,
    },
    {
      type: "phone-number",
      label: "Phone Number",
      isCustomField: true,
    },
    {
      type: "number-field",
      label: "Number",
      isCustomField: true,
    },
    {
      type: "date-field",
      label: "Date",
      isCustomField: true,
    },
    {
      type: "dropdown-list",
      label: "Dropdown List",
      isCustomField: true,
    },
    {
      type: "radio-button-list",
      label: "Radio button list",
      isCustomField: true,
    },
    {
      type: "checkbox-field",
      label: "Checkbox",
      isCustomField: true,
    },
    {
      type: "signature-field",
      label: "Digital Signature",
      isCustomField: true,
    },
  ];

  // const [, dragStudentAccountFields] = useDrag({
  //   type: "section-type",
  //   item: {
  //     name: "Student Account",
  //     fieldComponents: [
  //       {
  //         label: "Full Legal Name",
  //         type: "short-text",
  //         x: 0,
  //         y: 0,
  //       },
  //       {
  //         label: "First Name",
  //         type: "short-text",
  //         x: 4,
  //         y: 0,
  //       },
  //       {
  //         label: "Middle Name",
  //         type: "short-text",
  //         x: 8,
  //         y: 0,
  //       },
  //       {
  //         label: "Last Name",
  //         type: "short-text",
  //         x: 0,
  //         y: 1,
  //       },
  //       {
  //         label: "Preferred Name",
  //         type: "short-text",
  //         importTable: "students",
  //         importColumn: "preferred_name",
  //         x: 4,
  //         y: 1,
  //       },
  //       {
  //         label: "Birthdate",
  //         type: "date-field",
  //         x: 8,
  //         y: 1,
  //       },
  //       {
  //         label: "Place of Birth",
  //         type: "short-text",
  //         importTable: "students",
  //         importColumn: "place_of_birth",
  //         x: 0,
  //         y: 2,
  //       },
  //       {
  //         label: "Gender",
  //         type: "dropdown-list",
  //         importTable: "students",
  //         importColumn: "gender",
  //         x: 4,
  //         y: 2,
  //       },
  //       {
  //         label: "Ethnicity",
  //         type: "dropdown-list",
  //         importTable: "students",
  //         importColumn: "ethnicity",
  //         x: 8,
  //         y: 2,
  //       },
  //       {
  //         label: "Language",
  //         type: "dropdown-list",
  //         importTable: "students",
  //         importColumn: "language",
  //         x: 0,
  //         y: 3,
  //       },
  //       { label: "Is a Member of Church", type: "checkbox-field", x: 4, y: 3 },
  //       {
  //         label: "Denomination",
  //         type: "dropdown-list",
  //         importTable: "students",
  //         importColumn: "denomination",
  //         x: 8,
  //         y: 3,
  //       },
  //       {
  //         label: "Primary Email",
  //         type: "email-address",
  //         importTable: "students",
  //         importColumn: "primary_email",
  //         x: 0,
  //         y: 4,
  //       },
  //       {
  //         label: "Secondary Email",
  //         type: "email-address",
  //         importTable: "students",
  //         importColumn: "secondary_email",
  //         x: 4,
  //         y: 4,
  //       },
  //       {
  //         label: "Student Mobile Number",
  //         type: "phone-number",
  //         importTable: "students",
  //         importColumn: "phone_number",
  //         x: 8,
  //         y: 4,
  //       },
  //     ],
  //   },
  // });
  const studentAccount = [
    {
      label: "Full Legal Name",
      type: "short-text",
    },
    {
      label: "First Name",
      type: "short-text",
    },
    {
      label: "Middle Name",
      type: "short-text",
    },
    {
      label: "Last Name",
      type: "short-text",
    },
    {
      label: "Preferred Name",
      type: "short-text",
      importTable: "students",
      importColumn: "preferred_name",
    },
    {
      label: "Birthdate",
      type: "date-field",
    },
    {
      label: "Place of Birth",
      type: "short-text",
      importTable: "students",
      importColumn: "place_of_birth",
    },
    {
      label: "Gender",
      type: "dropdown-list",
      importTable: "students",
      importColumn: "gender",
    },
    {
      label: "Ethnicity",
      type: "dropdown-list",
      importTable: "students",
      importColumn: "ethnicity",
    },
    {
      label: "Language",
      type: "dropdown-list",
      importTable: "students",
      importColumn: "language",
    },
    { label: "Is a Member of Church", type: "checkbox-field" },
    {
      label: "Denomination",
      type: "dropdown-list",
      importTable: "students",
      importColumn: "denomination",
    },
    {
      label: "Home Church",
      type: "short-text",
      importTable: "students",
      importColumn: "home_church",
    },
    {
      label: "Primary Email",
      type: "email-address",
      importTable: "students",
      importColumn: "primary_email",
    },
    {
      label: "Secondary Email",
      type: "email-address",
      importTable: "students",
      importColumn: "secondary_email",
    },
    {
      label: "Student Mobile Number",
      type: "phone-number",
      importTable: "students",
      importColumn: "phone_number",
    },
  ];

  // const [, dragStudentMedicalFields] = useDrag({
  //   type: "section-type",
  //   item: {
  //     name: "Student Medical",
  //     fieldComponents: [
  //       {
  //         label: "Doctor Name",
  //         type: "short-text",
  //         importTable: "student_medical_details",
  //         importColumn: "doctor_name",
  //         x: 0,
  //         y: 0,
  //       },
  //       {
  //         label: "Clinic Name",
  //         type: "short-text",
  //         name: "Doctor's clinic name",
  //         importTable: "student_medical_details",
  //         importColumn: "doctor_hospital_name",
  //         x: 4,
  //         y: 0,
  //       },
  //       {
  //         label: "Phone Number",
  //         type: "phone-number",
  //         name: "Doctor's phone number",
  //         importTable: "student_medical_details",
  //         importColumn: "doctor_phone_number",
  //         x: 8,
  //         y: 0,
  //       },
  //       {
  //         label: "Dentist Name",
  //         type: "short-text",
  //         importTable: "student_medical_details",
  //         importColumn: "dentist_name",
  //         x: 0,
  //         y: 1,
  //       },
  //       {
  //         label: "Clinic Name",
  //         type: "short-text",
  //         name: "Dentist's clinic name",
  //         importTable: "student_medical_details",
  //         importColumn: "dentist_hospital_name",
  //         x: 4,
  //         y: 1,
  //       },
  //       {
  //         label: "Phone Number",
  //         type: "phone-number",
  //         name: "Dentist's phone number",
  //         importTable: "student_medical_details",
  //         importColumn: "dentist_phone_number",
  //         x: 8,
  //         y: 1,
  //       },
  //       {
  //         label: "Insurance company Name",
  //         type: "short-text",
  //         importTable: "student_medical_details",
  //         importColumn: "insurance_company_name",
  //         x: 0,
  //         y: 2,
  //       },
  //       {
  //         label: "Policy Number",
  //         type: "short-text",
  //         importTable: "student_medical_details",
  //         importColumn: "insurance_policy_number",
  //         x: 4,
  //         y: 2,
  //       },
  //     ],
  //   },
  // });
  const studentMedical = [
    {
      label: "Doctor Name",
      type: "short-text",
      importTable: "student_medical_details",
      importColumn: "doctor_name",
    },
    {
      label: "Clinic Name",
      type: "short-text",
      name: "Doctor's clinic name",
      importTable: "student_medical_details",
      importColumn: "doctor_hospital_name",
    },
    {
      label: "Phone Number",
      type: "phone-number",
      name: "Doctor's phone number",
      importTable: "student_medical_details",
      importColumn: "doctor_phone_number",
    },
    {
      label: "Dentist Name",
      type: "short-text",
      importTable: "student_medical_details",
      importColumn: "dentist_name",
    },
    {
      label: "Clinic Name",
      type: "short-text",
      name: "Dentist's clinic name",
      importTable: "student_medical_details",
      importColumn: "dentist_hospital_name",
    },
    {
      label: "Phone Number",
      type: "phone-number",
      name: "Dentist's phone number",
      importTable: "student_medical_details",
      importColumn: "dentist_phone_number",
    },
    {
      label: "Insurance company Name",
      type: "short-text",
      importTable: "student_medical_details",
      importColumn: "insurance_company_name",
    },
    {
      label: "Policy Number",
      type: "short-text",
      importTable: "student_medical_details",
      importColumn: "insurance_policy_number",
    },
  ];
  const sameAddressFieldId = `_${generateGUID().replaceAll("-", "_")}`;
  const visibilityConditionJS = `(${sameAddressFieldId} == "false")`;
  const visibilityCondition = [
    {
      conditionNodes: [
        {
          expression: {
            operand1: sameAddressFieldId,
            operator: "==",
            valueType: 1,
            operand2: "",
            value: "false",
          },
          type: 2,
        },
      ],
      type: 1,
    },
  ];

  const gFirstNameFieldId = `_${generateGUID().replaceAll("-", "_")}`;
  const gRelationshipFieldId = `_${generateGUID().replaceAll("-", "_")}`;
  const gLastNameFieldId = `_${generateGUID().replaceAll("-", "_")}`;

  const gMailingAddressLine1FieldId = `_${generateGUID().replaceAll("-", "_")}`;
  const gMailingCityFieldId = `_${generateGUID().replaceAll("-", "_")}`;
  const gMailingStateFieldId = `_${generateGUID().replaceAll("-", "_")}`;
  const gMailingPostalCodeFieldId = `_${generateGUID().replaceAll("-", "_")}`;
  const gMailingCountryFieldId = `_${generateGUID().replaceAll("-", "_")}`;

  const gPhysicalAddressLine1FieldId = `_${generateGUID().replaceAll(
    "-",
    "_"
  )}`;
  const gPhysicalCityFieldId = `_${generateGUID().replaceAll("-", "_")}`;
  const gPhysicalStateFieldId = `_${generateGUID().replaceAll("-", "_")}`;
  const gPhysicalPostalCodeFieldId = `_${generateGUID().replaceAll("-", "_")}`;
  const gPhysicalCountryFieldId = `_${generateGUID().replaceAll("-", "_")}`;

  const generateValidityCondition = (operand1, operand2) => [
    {
      conditionNodes: [
        {
          expression: {
            operand1,
          },
          type: 2,
        },
        {
          logicalOperator: "0",
          expression: {
            operand1: operand2,
          },
          type: 2,
        },
        {
          logicalOperator: 1,
          expression: {
            operand1,
          },
          type: 2,
        },
      ],
      type: 1,
    },
  ];

  const [, dragParentFields] = useDrag({
    type: "section-type",
    item: {
      name: "Parent Information",
      fieldComponents: [
        {
          type: "dropdown-list",
          label: "Relationship to the student",
          importTable: "guardians_students",
          importColumn: "relationship",
          fieldId: gRelationshipFieldId,
          x: 0,
          y: 0,
          properties: {
            dbRequiredField: true,
            validityMessage: requiredMessage,
            validityConditionJS: `((${String(
              gFirstNameFieldId
            )}).length > 0 && (${gRelationshipFieldId}).length > 0 || ${String(
              gFirstNameFieldId
            )} == "")`,
            validityCondition: generateValidityCondition(
              String(gFirstNameFieldId),
              gRelationshipFieldId
            ),
          },
        },
        {
          type: "short-text",
          label: "First Name",
          importTable: "guardians",
          importColumn: "first_name",
          fieldId: String(gFirstNameFieldId),
          x: 4,
          y: 0,
          properties: {
            dbRequiredField: true,
          },
        },
        {
          type: "short-text",
          label: "Middle Name",
          importTable: "guardians",
          importColumn: "middle_name",
          x: 8,
          y: 0,
        },
        {
          type: "short-text",
          label: "Last Name",
          importTable: "guardians",
          importColumn: "last_name",
          fieldId: gLastNameFieldId,
          x: 0,
          y: 1,
          properties: {
            dbRequiredField: true,
            validityMessage: requiredMessage,
            validityConditionJS: `((${String(
              gFirstNameFieldId
            )}).length > 0 && (${gLastNameFieldId}).length > 0 || ${String(
              gFirstNameFieldId
            )} == "")`,
            validityCondition: generateValidityCondition(
              String(gFirstNameFieldId),
              gLastNameFieldId
            ),
          },
        },
        {
          type: "phone-number",
          label: "Cell Phone",
          importTable: "guardians",
          importColumn: "cell_phone",
          x: 4,
          y: 1,
        },
        {
          type: "phone-number",
          label: "Secondary Phone",
          importTable: "guardians",
          importColumn: "secondary_phone",
          x: 8,
          y: 1,
        },
        {
          type: "email-address",
          label: "Email",
          importTable: "guardians",
          importColumn: "email",
          x: 0,
          y: 2,
        },
        {
          type: "short-text",
          label: "Home Church",
          importTable: "guardians",
          importColumn: "home_church",
          x: 4,
          y: 2,
        },
        {
          type: "checkbox-field",
          label: "Is Adventist",
          importTable: "guardians",
          importColumn: "is_adventist",
          x: 8,
          y: 2,
        },
        {
          type: "horizontal-divider",
          x: 0,
          y: 3,
        },
        {
          type: "sub-heading",
          properties: { subHeadingText: "Mailing Address" },
          x: 0,
          y: 4,
        },
        {
          label: "Address 1",
          type: "short-text",
          importTable: "mailing_addresses",
          importColumn: "address_line1",
          fieldId: gMailingAddressLine1FieldId,
          x: 0,
          y: 5,
          properties: {
            dbRequiredField: true,
            validityMessage: requiredMessage,
            validityConditionJS: `((${String(
              gFirstNameFieldId
            )}).length > 0 && (${gMailingAddressLine1FieldId}).length > 0 || ${String(
              gFirstNameFieldId
            )} == "")`,
            validityCondition: generateValidityCondition(
              String(gFirstNameFieldId),
              gMailingAddressLine1FieldId
            ),
          },
        },
        {
          label: "Address 2",
          type: "short-text",
          importTable: "mailing_addresses",
          importColumn: "address_line2",
          x: 4,
          y: 5,
        },
        {
          label: "City",
          type: "short-text",
          importTable: "mailing_addresses",
          importColumn: "city",
          fieldId: gMailingCityFieldId,
          x: 8,
          y: 5,
          properties: {
            dbRequiredField: true,
            validityMessage: requiredMessage,
            validityConditionJS: `((${String(
              gFirstNameFieldId
            )}).length > 0 && (${gMailingCityFieldId}).length > 0 || ${String(
              gFirstNameFieldId
            )} == "")`,
            validityCondition: generateValidityCondition(
              String(gFirstNameFieldId),
              gMailingCityFieldId
            ),
          },
        },
        {
          label: "State",
          type: "dropdown-list",
          importTable: "mailing_addresses",
          importColumn: "state_id",
          fieldId: gMailingStateFieldId,
          x: 0,
          y: 6,
          properties: {
            dbRequiredField: true,
            validityMessage: requiredMessage,
            validityConditionJS: `((${String(
              gFirstNameFieldId
            )}).length > 0 && (${gMailingStateFieldId}).length > 0 || ${String(
              gFirstNameFieldId
            )} == "")`,
            validityCondition: generateValidityCondition(
              String(gFirstNameFieldId),
              gMailingStateFieldId
            ),
          },
        },
        {
          label: "Postal Code",
          type: "number-field",
          importTable: "mailing_addresses",
          importColumn: "postal_code",
          fieldId: gMailingPostalCodeFieldId,
          x: 4,
          y: 6,
          properties: {
            dbRequiredField: true,
            validityMessage: requiredMessage,
            validityConditionJS: `((${String(
              gFirstNameFieldId
            )}).length > 0 && (${gMailingPostalCodeFieldId}).length > 0 || ${String(
              gFirstNameFieldId
            )} == "")`,
            validityCondition: generateValidityCondition(
              String(gFirstNameFieldId),
              gMailingPostalCodeFieldId
            ),
          },
        },
        {
          label: "Country",
          type: "dropdown-list",
          importTable: "mailing_addresses",
          importColumn: "country_id",
          fieldId: gMailingCountryFieldId,
          x: 8,
          y: 6,
          properties: {
            dbRequiredField: true,
            validityMessage: requiredMessage,
            validityConditionJS: `((${String(
              gFirstNameFieldId
            )}).length > 0 && (${gMailingCountryFieldId}).length > 0 || ${String(
              gFirstNameFieldId
            )} == "")`,
            validityCondition: generateValidityCondition(
              String(gFirstNameFieldId),
              gMailingCountryFieldId
            ),
          },
        },
        {
          type: "horizontal-divider",
          x: 0,
          y: 7,
        },
        {
          type: "sub-heading",
          properties: { subHeadingText: "Physical Address" },
          x: 0,
          y: 8,
        },
        {
          label: "Same as mailing address",
          fieldId: sameAddressFieldId,
          type: "checkbox-field",
          importTable: "physical_addresses",
          importColumn: "same_as_physical_address",
          properties: { dbRequiredField: true },
          w: 12,
          x: 0,
          y: 9,
        },
        {
          label: "Address 1",
          type: "short-text",
          importTable: "physical_addresses",
          importColumn: "address_line1",
          fieldId: gPhysicalAddressLine1FieldId,
          x: 0,
          y: 10,
          properties: {
            dbRequiredField: true,
            validityMessage: requiredMessage,
            validityConditionJS: `((${String(
              gFirstNameFieldId
            )}).length > 0 && (${gPhysicalAddressLine1FieldId}).length > 0 || ${String(
              gFirstNameFieldId
            )} == "")`,
            validityCondition: generateValidityCondition(
              String(gFirstNameFieldId),
              gPhysicalAddressLine1FieldId
            ),
            visibilityConditionJS,
            visibilityCondition,
          },
        },
        {
          label: "Address 2",
          type: "short-text",
          importTable: "physical_addresses",
          importColumn: "address_line2",
          properties: {
            visibilityConditionJS,
            visibilityCondition,
          },
          x: 4,
          y: 10,
        },
        {
          label: "City",
          type: "short-text",
          importTable: "physical_addresses",
          importColumn: "city",
          fieldId: gPhysicalCityFieldId,
          x: 8,
          y: 10,
          properties: {
            dbRequiredField: true,
            validityMessage: requiredMessage,
            validityConditionJS: `((${String(
              gFirstNameFieldId
            )}).length > 0 && (${gPhysicalCityFieldId}).length > 0 || ${String(
              gFirstNameFieldId
            )} == "")`,
            validityCondition: generateValidityCondition(
              String(gFirstNameFieldId),
              gPhysicalCityFieldId
            ),
            visibilityConditionJS,
            visibilityCondition,
          },
        },
        {
          label: "State",
          type: "dropdown-list",
          importTable: "physical_addresses",
          importColumn: "state_id",
          fieldId: gPhysicalStateFieldId,
          properties: {
            dbRequiredField: true,
            validityMessage: requiredMessage,
            validityConditionJS: `((${String(
              gFirstNameFieldId
            )}).length > 0 && (${gPhysicalStateFieldId}).length > 0 || ${String(
              gFirstNameFieldId
            )} == "")`,
            validityCondition: generateValidityCondition(
              String(gFirstNameFieldId),
              gPhysicalStateFieldId
            ),
            visibilityConditionJS,
            visibilityCondition,
          },
          x: 0,
          y: 11,
        },
        {
          label: "Postal Code",
          type: "number-field",
          importTable: "physical_addresses",
          importColumn: "postal_code",
          fieldId: gPhysicalPostalCodeFieldId,
          properties: {
            dbRequiredField: true,
            validityMessage: requiredMessage,
            validityConditionJS: `((${String(
              gFirstNameFieldId
            )}).length > 0 && (${gPhysicalPostalCodeFieldId}).length > 0 || ${String(
              gFirstNameFieldId
            )} == "")`,
            validityCondition: generateValidityCondition(
              String(gFirstNameFieldId),
              gPhysicalPostalCodeFieldId
            ),
            visibilityConditionJS,
            visibilityCondition,
          },
          x: 4,
          y: 11,
        },
        {
          label: "Country",
          type: "dropdown-list",
          importTable: "physical_addresses",
          importColumn: "country_id",
          fieldId: gPhysicalCountryFieldId,
          properties: {
            dbRequiredField: true,
            validityMessage: requiredMessage,
            validityConditionJS: `((${String(
              gFirstNameFieldId
            )}).length > 0 && (${gPhysicalCountryFieldId}).length > 0 || ${String(
              gFirstNameFieldId
            )} == "")`,
            validityCondition: generateValidityCondition(
              String(gFirstNameFieldId),
              gPhysicalCountryFieldId
            ),
            visibilityConditionJS,
            visibilityCondition,
          },
          x: 8,
          y: 11,
        },
        {
          type: "horizontal-divider",
          x: 0,
          y: 12,
        },
        {
          type: "sub-heading",
          properties: { subHeadingText: "Options" },
          x: 0,
          y: 13,
        },
        {
          label: "Primary Contact",
          type: "checkbox-field",
          importTable: "guardians_students",
          importColumn: "is_primary_contact",
          x: 0,
          y: 14,
        },
        {
          label: "Approved Volunteer",
          type: "checkbox-field",
          importTable: "guardians",
          importColumn: "is_approved_volunteer",
          x: 4,
          y: 14,
        },
        {
          label: "Emergency Contact",
          type: "checkbox-field",
          importTable: "guardians_students",
          importColumn: "is_emergency_contact",
          x: 8,
          y: 14,
        },
        {
          label: "Custodial Parent",
          type: "checkbox-field",
          importTable: "guardians_students",
          importColumn: "is_custodial_parent",
          x: 0,
          y: 15,
        },
        {
          label: "Authorized Pickup",
          type: "checkbox-field",
          importTable: "guardians",
          importColumn: "authorized_pickup",
          x: 4,
          y: 15,
        },
        {
          label: "Vehicle License",
          type: "short-text",
          importTable: "guardians",
          importColumn: "authorized_pickup_vehicle_license",
          x: 8,
          y: 15,
        },
        {
          label: "School Transportation",
          type: "checkbox-field",
          importTable: "guardians",
          importColumn: "school_transportation",
          x: 0,
          y: 16,
        },
        {
          label: "Vehicle License",
          type: "short-text",
          importTable: "guardians",
          importColumn: "school_transportation_vehicle_license",
          x: 4,
          y: 16,
        },
        {
          type: "horizontal-divider",
          x: 0,
          y: 17,
        },
        {
          type: "sub-heading",
          properties: { subHeadingText: "Notifications" },
          x: 0,
          y: 18,
        },
        {
          label: "Text",
          type: "checkbox-field",
          importTable: "guardians",
          importColumn: "text_notifications",
          x: 0,
          y: 19,
        },
        {
          label: "Email",
          type: "checkbox-field",
          importTable: "guardians",
          importColumn: "email_notifications",
          x: 4,
          y: 19,
        },
        {
          type: "horizontal-divider",
          x: 0,
          y: 20,
        },
        {
          type: "sub-heading",
          properties: { subHeadingText: "Notes" },
          x: 4,
          y: 21,
        },
        {
          label: "Notes",
          type: "long-text",
          importTable: "guardians",
          importColumn: "notes",
          x: 0,
          y: 22,
          w: 12,
        },
      ].concat(customGuardianFields),
    },
  });
  // const parentFields1 = [
  //   {
  //     type: "dropdown-list",
  //     label: "Relationship to the student",
  //     importTable: "guardians_students",
  //     importColumn: "relationship",
  //   },
  //   {
  //     type: "short-text",
  //     label: "First Name",
  //     importTable: "guardians",
  //     importColumn: "first_name",
  //   },
  //   {
  //     type: "short-text",
  //     label: "Middle Name",
  //     importTable: "guardians",
  //     importColumn: "middle_name",
  //   },
  //   {
  //     type: "short-text",
  //     label: "Last Name",
  //     importTable: "guardians",
  //     importColumn: "last_name",
  //   },
  //   {
  //     type: "phone-number",
  //     label: "Cell Phone",
  //     importTable: "guardians",
  //     importColumn: "cell_phone",
  //   },
  //   {
  //     type: "phone-number",
  //     label: "Secondary Phone",
  //     importTable: "guardians",
  //     importColumn: "secondary_phone",
  //   },
  //   {
  //     type: "email-address",
  //     label: "Email",
  //     importTable: "guardians",
  //     importColumn: "email",
  //   },
  //   {
  //     type: "short-text",
  //     label: "Home Church",
  //     importTable: "guardians",
  //     importColumn: "home_church",
  //   },
  //   {
  //     type: "checkbox-field",
  //     label: "Is Adventist",
  //     importTable: "guardians",
  //     importColumn: "is_adventist",
  //   },
  // ];
  // const mailingAddressFields = [
  //   {
  //     label: "Address 1",
  //     type: "short-text",
  //     importTable: "mailing_addresses",
  //     importColumn: "address_line1",
  //   },
  //   {
  //     label: "Address 2",
  //     type: "short-text",
  //     importTable: "mailing_addresses",
  //     importColumn: "address_line2",
  //   },
  //   {
  //     label: "City",
  //     type: "short-text",
  //     importTable: "mailing_addresses",
  //     importColumn: "city",
  //   },
  //   {
  //     label: "State",
  //     type: "dropdown-list",
  //     importTable: "mailing_addresses",
  //     importColumn: "state_id",
  //   },
  //   {
  //     label: "Postal Code",
  //     type: "number-field",
  //     importTable: "mailing_addresses",
  //     importColumn: "postal_code",
  //   },
  //   {
  //     label: "Country",
  //     type: "dropdown-list",
  //     importTable: "mailing_addresses",
  //     importColumn: "country_id",
  //   },
  // ];

  // const physicalAddressFields = [
  //   {
  //     label: "Address 1",
  //     type: "short-text",
  //     importTable: "physical_addresses",
  //     importColumn: "address_line1",
  //   },
  //   {
  //     label: "Address 2",
  //     type: "short-text",
  //     importTable: "physical_addresses",
  //     importColumn: "address_line2",
  //   },
  //   {
  //     label: "City",
  //     type: "short-text",
  //     importTable: "physical_addresses",
  //     importColumn: "city",
  //   },
  //   {
  //     label: "State",
  //     type: "dropdown-list",
  //     importTable: "physical_addresses",
  //     importColumn: "state_id",
  //   },
  //   {
  //     label: "Postal Code",
  //     type: "number-field",
  //     importTable: "physical_addresses",
  //     importColumn: "postal_code",
  //   },
  //   {
  //     label: "Country",
  //     type: "dropdown-list",
  //     importTable: "physical_addresses",
  //     importColumn: "country_id",
  //   },
  // ];

  // const parentFields2 = [
  //   {
  //     label: "Primary Contact",
  //     type: "checkbox-field",
  //     importTable: "guardians_students",
  //     importColumn: "is_primary_contact",
  //   },
  //   {
  //     label: "Approved Volunteer",
  //     type: "checkbox-field",
  //     importTable: "guardians",
  //     importColumn: "is_approved_volunteer",
  //   },
  //   {
  //     label: "Emergency Contact",
  //     type: "checkbox-field",
  //     importTable: "guardians_students",
  //     importColumn: "is_emergency_contact",
  //   },
  //   {
  //     label: "Custodial Parent",
  //     type: "checkbox-field",
  //     importTable: "guardians_students",
  //     importColumn: "is_custodial_parent",
  //   },
  //   {
  //     label: "Authorized Pickup",
  //     type: "checkbox-field",
  //     importTable: "guardians",
  //     importColumn: "authorized_pickup",
  //   },
  //   {
  //     label: "Vehicle License",
  //     type: "short-text",
  //     importTable: "guardians",
  //     importColumn: "authorized_pickup_vehicle_license",
  //   },
  //   {
  //     label: "School Transportation",
  //     type: "checkbox-field",
  //     importTable: "guardians",
  //     importColumn: "school_transportation",
  //   },
  //   {
  //     label: "Vehicle License",
  //     type: "short-text",
  //     importTable: "guardians",
  //     importColumn: "school_transportation_vehicle_license",
  //   },
  // ];
  // const parentNotificationFields = [
  //   {
  //     label: "Text",
  //     type: "checkbox-field",
  //     importTable: "guardians",
  //     importColumn: "text_notifications",
  //   },
  //   {
  //     label: "Email",
  //     type: "checkbox-field",
  //     importTable: "guardians",
  //     importColumn: "email_notifications",
  //   },
  // ];
  // const parentFields3 = [
  //   {
  //     label: "Notes",
  //     type: "long-text",
  //     importTable: "guardians",
  //     importColumn: "notes",
  //   },
  // ];

  const ecFirstNameFieldId = `_${generateGUID().replaceAll("-", "_")}`;
  const ecRelationshipFieldId = `_${generateGUID().replaceAll("-", "_")}`;
  const ecLastNameFieldId = `_${generateGUID().replaceAll("-", "_")}`;

  const ecRelationshipValidityCondition = [
    {
      conditionNodes: [
        {
          expression: {
            operand1: ecFirstNameFieldId,
          },
          type: 2,
        },
        {
          logicalOperator: "0",
          expression: {
            operand1: ecRelationshipFieldId,
          },
          type: 2,
        },
        {
          logicalOperator: 1,
          expression: {
            operand1: ecFirstNameFieldId,
          },
          type: 2,
        },
      ],
      type: 1,
    },
  ];

  const ecLastNameValidityCondition = [
    {
      conditionNodes: [
        {
          expression: {
            operand1: ecFirstNameFieldId,
          },
          type: 2,
        },
        {
          logicalOperator: "0",
          expression: {
            operand1: ecLastNameFieldId,
          },
          type: 2,
        },
        {
          logicalOperator: 1,
          expression: {
            operand1: ecFirstNameFieldId,
          },
          type: 2,
        },
      ],
      type: 1,
    },
  ];

  const [, dragEmergencyContact] = useDrag({
    type: "section-type",
    item: {
      name: "Emergency Contact",
      fieldComponents: [
        {
          type: "dropdown-list",
          label: "Relationship to the student",
          importTable: "emergency_contacts",
          importColumn: "relationship",
          fieldId: ecRelationshipFieldId,
          x: 0,
          y: 0,
          properties: {
            dbRequiredField: true,
            validityMessage: requiredMessage,
            validityConditionJS: `((${ecFirstNameFieldId}).length > 0 && (${ecRelationshipFieldId}).length > 0 || ${ecFirstNameFieldId} == "")`,
            validityCondition: ecRelationshipValidityCondition,
          },
        },
        {
          type: "short-text",
          label: "First Name",
          importTable: "emergency_contacts",
          importColumn: "first_name",
          fieldId: ecFirstNameFieldId,
          x: 4,
          y: 0,
          properties: {
            dbRequiredField: true,
          },
        },
        {
          type: "short-text",
          label: "Middle Name",
          importTable: "emergency_contacts",
          importColumn: "middle_name",
          x: 8,
          y: 0,
        },
        {
          type: "short-text",
          label: "Last Name",
          importTable: "emergency_contacts",
          importColumn: "last_name",
          fieldId: ecLastNameFieldId,
          x: 0,
          y: 1,
          properties: {
            dbRequiredField: true,
            validityMessage: requiredMessage,
            validityConditionJS: `((${ecFirstNameFieldId}).length > 0 && (${ecLastNameFieldId}).length > 0 || ${ecFirstNameFieldId} == "")`,
            validityCondition: ecLastNameValidityCondition,
          },
        },
        {
          type: "phone-number",
          label: "Cell Phone",
          importTable: "emergency_contacts",
          importColumn: "cell_phone",
          x: 4,
          y: 1,
        },
        {
          type: "phone-number",
          label: "Secondary Phone",
          importTable: "emergency_contacts",
          importColumn: "secondary_phone",
          x: 8,
          y: 1,
        },
        {
          type: "email-address",
          label: "Email",
          importTable: "emergency_contacts",
          importColumn: "email",
          x: 0,
          y: 2,
        },
        {
          label: "Primary Contact",
          type: "checkbox-field",
          importTable: "emergency_contacts",
          importColumn: "is_primary",
          x: 4,
          y: 2,
        },
        {
          label: "Authorized Pickup",
          type: "checkbox-field",
          importTable: "emergency_contacts",
          importColumn: "is_authorized_pickup",
          x: 8,
          y: 2,
        },
        {
          label: "Vehicle License",
          type: "short-text",
          importTable: "emergency_contacts",
          importColumn: "vehicle_license",
          x: 0,
          y: 3,
        },
        {
          label: "School Transportation",
          type: "checkbox-field",
          importTable: "emergency_contacts",
          importColumn: "school_transportation",
          x: 4,
          y: 3,
        },
        {
          label: "Background check",
          type: "checkbox-field",
          importTable: "emergency_contacts",
          importColumn: "background_check",
          x: 8,
          y: 3,
        },
        {
          type: "horizontal-divider",
          x: 0,
          y: 4,
        },
        {
          label: "Text",
          type: "checkbox-field",
          importTable: "emergency_contacts",
          importColumn: "text_notifications",
          x: 0,
          y: 5,
        },
        {
          label: "Email",
          type: "checkbox-field",
          importTable: "emergency_contacts",
          importColumn: "email_notifications",
          x: 4,
          y: 5,
        },
        {
          type: "horizontal-divider",
          x: 0,
          y: 6,
        },
        {
          label: "Notes",
          type: "long-text",
          importTable: "emergency_contacts",
          importColumn: "notes",
          x: 0,
          y: 7,
          w: 12,
        },
      ],
    },
  });
  // const emergencyContactFields1 = [
  //   {
  //     type: "dropdown-list",
  //     label: "Relationship to the student",
  //     importTable: "emergency_contacts",
  //     importColumn: "relationship",
  //   },
  //   {
  //     type: "short-text",
  //     label: "First Name",
  //     importTable: "emergency_contacts",
  //     importColumn: "first_name",
  //   },
  //   {
  //     type: "short-text",
  //     label: "Middle Name",
  //     importTable: "emergency_contacts",
  //     importColumn: "middle_name",
  //   },
  //   {
  //     type: "short-text",
  //     label: "Last Name",
  //     importTable: "emergency_contacts",
  //     importColumn: "last_name",
  //   },
  //   {
  //     type: "phone-number",
  //     label: "Cell Phone",
  //     importTable: "emergency_contacts",
  //     importColumn: "cell_phone",
  //   },
  //   {
  //     type: "phone-number",
  //     label: "Secondary Phone",
  //     importTable: "emergency_contacts",
  //     importColumn: "secondary_phone",
  //   },
  //   {
  //     type: "email-address",
  //     label: "Email",
  //     importTable: "emergency_contacts",
  //     importColumn: "email",
  //   },
  //   {
  //     label: "Primary Contact",
  //     type: "checkbox-field",
  //     importTable: "emergency_contacts",
  //     importColumn: "is_primary",
  //   },
  //   {
  //     label: "Authorized Pickup",
  //     type: "checkbox-field",
  //     importTable: "emergency_contacts",
  //     importColumn: "is_authorized_pickup",
  //   },
  //   {
  //     label: "Vehicle License",
  //     type: "short-text",
  //     importTable: "emergency_contacts",
  //     importColumn: "vehicle_license",
  //   },
  //   {
  //     label: "School Transportation",
  //     type: "checkbox-field",
  //     importTable: "emergency_contacts",
  //     importColumn: "school_transportation",
  //   },
  //   {
  //     label: "Background check",
  //     type: "checkbox-field",
  //     importTable: "emergency_contacts",
  //     importColumn: "background_check",
  //   },
  // ];

  // const emergencyContactReceiveNotifications = [
  //   {
  //     label: "Text",
  //     type: "checkbox-field",
  //     importTable: "emergency_contacts",
  //     importColumn: "text_notifications",
  //   },
  //   {
  //     label: "Email",
  //     type: "checkbox-field",
  //     importTable: "emergency_contacts",
  //     importColumn: "email_notifications",
  //   },
  // ];

  // const emergencyContactFields2 = [
  //   {
  //     label: "Notes",
  //     type: "long-text",
  //     importTable: "emergency_contacts",
  //     importColumn: "notes",
  //   },
  // ];

  useEffect(() => {
    customFieldsService.fetchAllCustomFields().then((response) => {
      response.data = response.data.filter((f) => f.properties.isVisible);
      setCustomStudentProfileFields(
        response.data.filter((f) => f.section_type === "student_profile")
      );
      setCustomStudentMedicalFields(
        response.data.filter((f) => f.section_type === "student_medical")
      );
      const guardianFields = response.data.filter(
        (f) => f.section_type === "guardian_profile"
      );
      const updateParentFieldsComponent = [];
      updateParentFieldsComponent.push({
        type: "horizontal-divider",
        x: 0,
        y: 23,
      });
      let x = 0;
      let y = 24;
      guardianFields.forEach((f) => {
        const gField = f;
        gField.x = x;
        gField.y = y;
        gField.type = gField.field_type;
        gField.field_id = null;
        gField.customFieldId = gField.id;
        gField.customFieldResponseType = gField.response_type;
        gField.id = null;
        updateParentFieldsComponent.push(gField);
        if (x === 8) {
          x = 0;
          y += 1;
        } else {
          x += 4;
        }
      });
      setCustomGuardianFields(updateParentFieldsComponent);
    });
  }, []);

  return (
    <Box width="100%" sx={{ ".MuiAccordion-root:before": { display: "none" } }}>
      <Accordion
        sx={{
          boxShadow: "none",
          p: 0.5,
          ".MuiAccordionSummary-root": {
            minHeight: "fit-content !important",
            padding: "0 !important",
          },
        }}
        expanded={accordionState.includes("custom_fields")}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          sx={{
            ".MuiAccordionSummary-content": {
              margin: "0 !important",
            },
          }}
          onClick={() =>
            setAccordionState(
              accordionState.includes("custom_fields") ? "" : "custom_fields"
            )
          }
        >
          <Typography variant="body1">Custom Fields</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {customFieldsArray.map((field) => (
            <DraggableField key={field.label} field={field} />
          ))}
          {/* {!!customStudentProfileFields.length && (
            <Accordion
              sx={{
                boxShadow: "none",
                ".MuiAccordionSummary-root": {
                  minHeight: "fit-content !important",
                  padding: "0 !important",
                },
                mt: 1,
              }}
              expanded={accordionState.includes(
                "custom_fields-student_profile_fields"
              )}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                sx={{
                  ".MuiAccordionSummary-content": {
                    margin: "0 !important",
                  },
                }}
                onClick={() =>
                  setAccordionState(
                    accordionState.includes(
                      "custom_fields-student_profile_fields"
                    )
                      ? "custom_fields"
                      : "custom_fields-student_profile_fields"
                  )
                }
              >
                <Typography variant="body1">Student Profile</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {customStudentProfileFields.map((field) => (
                  <DraggableField
                    key={field.label}
                    field={{
                      ...field,
                      customFieldId: field.id,
                      customFieldResponseType: field.response_type,
                      field_id: null,
                    }}
                  />
                ))}
              </AccordionDetails>
            </Accordion>
          )}
          {!!customStudentMedicalFields.length && (
            <Accordion
              sx={{
                boxShadow: "none",
                ".MuiAccordionSummary-root": {
                  minHeight: "fit-content !important",
                  padding: "0 !important",
                },
                mt: 1,
              }}
              expanded={accordionState.includes(
                "custom_fields-student_medical_fields"
              )}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                sx={{
                  ".MuiAccordionSummary-content": {
                    margin: "0 !important",
                  },
                }}
                onClick={() =>
                  setAccordionState(
                    accordionState.includes(
                      "custom_fields-student_medical_fields"
                    )
                      ? "custom_fields"
                      : "custom_fields-student_medical_fields"
                  )
                }
              >
                <Typography variant="body1">Student Medical</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {customStudentMedicalFields.map((field) => (
                  <DraggableField
                    key={field.label}
                    field={{
                      ...field,
                      customFieldId: field.id,
                      customFieldResponseType: field.response_type,
                      field_id: null,
                    }}
                  />
                ))}
              </AccordionDetails>
            </Accordion>
          )}
          {!!customGuardianFields.length && (
            <Accordion
              sx={{
                boxShadow: "none",
                ".MuiAccordionSummary-root": {
                  minHeight: "fit-content !important",
                  padding: "0 !important",
                },
                mt: 1,
              }}
              expanded={accordionState.includes(
                "custom_fields-guardian_fields"
              )}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                sx={{
                  ".MuiAccordionSummary-content": {
                    margin: "0 !important",
                  },
                }}
                onClick={() =>
                  setAccordionState(
                    accordionState.includes("custom_fields-guardian_fields")
                      ? "custom_fields"
                      : "custom_fields-guardian_fields"
                  )
                }
              >
                <Typography variant="body1">Parent</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {customGuardianFields.map((field) => (
                  <DraggableField
                    key={field.label}
                    field={{
                      ...field,
                      customFieldId: field.id,
                      customFieldResponseType: field.response_type,
                      field_id: null,
                    }}
                  />
                ))}
              </AccordionDetails>
            </Accordion>
          )} */}
        </AccordionDetails>
      </Accordion>
      {/* <Grid
        container
        ref={dragStudentAccountFields}
        onDragStart={() =>
          formBuilderContext.fireOnDragStart({ type: "section" })
        }
        onDragEnd={() => {
          formBuilderContext.fireOnDragStop();
        }}
        sx={fieldStyle}
      >
        <Grid item>
          <Typography variant="body1">Student Account</Typography>
        </Grid>
        <Grid item>
          <ArrowRightAlt fontSize="small" />
          <Segment fontSize="small" />
        </Grid>
      </Grid> */}
      {/* <Grid
        container
        ref={dragStudentMedicalFields}
        onDragStart={() =>
          formBuilderContext.fireOnDragStart({ type: "section" })
        }
        onDragEnd={() => {
          formBuilderContext.fireOnDragStop();
        }}
        sx={fieldStyle}
      >
        <Grid item>
          <Typography variant="body1">Student Medical</Typography>
        </Grid>
        <Grid item>
          <ArrowRightAlt fontSize="small" />
          <Segment fontSize="small" />
        </Grid>
      </Grid> */}
      <Accordion
        sx={{
          boxShadow: "none",
          p: 0.5,
          ".MuiAccordionSummary-root": {
            minHeight: "fit-content !important",
            padding: "0 !important",
          },
        }}
        expanded={accordionState.includes("student_fields")}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          sx={{
            ".MuiAccordionSummary-content": {
              margin: "0 !important",
            },
          }}
          onClick={() =>
            setAccordionState(
              accordionState.includes("student_fields") ? "" : "student_fields"
            )
          }
        >
          <Typography variant="body1">Student</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Accordion
            sx={{
              boxShadow: "none",
              ".MuiAccordionSummary-root": {
                minHeight: "fit-content !important",
                padding: "0 !important",
              },
              mt: 1,
            }}
            expanded={accordionState.includes(
              "student_fields-student_account_fields"
            )}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              sx={{
                ".MuiAccordionSummary-content": {
                  margin: "0 !important",
                },
              }}
              onClick={() =>
                setAccordionState(
                  accordionState.includes(
                    "student_fields-student_account_fields"
                  )
                    ? "student_fields"
                    : "student_fields-student_account_fields"
                )
              }
            >
              <Typography variant="body1">Student Account</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {studentAccount.map((field) => (
                <DraggableField key={field.label} field={field} />
              ))}
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{
              boxShadow: "none",
              ".MuiAccordionSummary-root": {
                minHeight: "fit-content !important",
                padding: "0 !important",
              },
              mt: 1,
            }}
            expanded={accordionState.includes(
              "student_fields-student_medical_fields"
            )}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              sx={{
                ".MuiAccordionSummary-content": {
                  margin: "0 !important",
                },
              }}
              onClick={() =>
                setAccordionState(
                  accordionState.includes(
                    "student_fields-student_medical_fields"
                  )
                    ? "student_fields"
                    : "student_fields-student_medical_fields"
                )
              }
            >
              <Typography variant="body1">Student Medical</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {studentMedical.map((field) => (
                <DraggableField key={field.label} field={field} />
              ))}
              {customStudentMedicalFields.map((field) => (
                <DraggableField
                  key={field.label}
                  field={{
                    ...field,
                    customFieldId: field.id,
                    customFieldResponseType: field.response_type,
                    field_id: null,
                  }}
                />
              ))}
            </AccordionDetails>
          </Accordion>
          {!!customStudentProfileFields.length && (
            <Accordion
              sx={{
                boxShadow: "none",
                ".MuiAccordionSummary-root": {
                  minHeight: "fit-content !important",
                  padding: "0 !important",
                },
                mt: 1,
              }}
              expanded={accordionState.includes(
                "student_fields-student_profile_fields"
              )}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                sx={{
                  ".MuiAccordionSummary-content": {
                    margin: "0 !important",
                  },
                }}
                onClick={() =>
                  setAccordionState(
                    accordionState.includes(
                      "student_fields-student_profile_fields"
                    )
                      ? "student_fields"
                      : "student_fields-student_profile_fields"
                  )
                }
              >
                <Typography variant="body1">Student Profile</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {customStudentProfileFields.map((field) => (
                  <DraggableField
                    key={field.label}
                    field={{
                      ...field,
                      customFieldId: field.id,
                      customFieldResponseType: field.response_type,
                      field_id: null,
                    }}
                  />
                ))}
              </AccordionDetails>
            </Accordion>
          )}
        </AccordionDetails>
      </Accordion>
      <Grid
        container
        ref={dragParentFields}
        onDragStart={() =>
          formBuilderContext.fireOnDragStart({ type: "section" })
        }
        onDragEnd={() => {
          formBuilderContext.fireOnDragStop();
        }}
        sx={fieldStyle}
      >
        <Grid item>
          <Typography variant="body1">Parent</Typography>
        </Grid>
        <Grid item>
          <ArrowRightAlt fontSize="small" />
          <Segment fontSize="small" />
        </Grid>
      </Grid>
      <Grid
        container
        ref={dragEmergencyContact}
        onDragStart={() =>
          formBuilderContext.fireOnDragStart({ type: "section" })
        }
        onDragEnd={() => {
          formBuilderContext.fireOnDragStop();
        }}
        sx={fieldStyle}
      >
        <Grid item>
          <Typography variant="body1">Emergency Contact</Typography>
        </Grid>
        <Grid item>
          <ArrowRightAlt fontSize="small" />
          <Segment fontSize="small" />
        </Grid>
      </Grid>

      {/* <Accordion
        sx={{
          boxShadow: "none",
          ".MuiAccordionSummary-root": {
            minHeight: "fit-content !important",
            padding: "0 !important",
          },
          mt: 1,
        }}
        expanded={accordionState.includes("parent_fields")}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          sx={{
            ".MuiAccordionSummary-content": {
              margin: "0 !important",
            },
          }}
          onClick={() =>
            setAccordionState(
              accordionState.includes("parent_fields") ? "" : "parent_fields"
            )
          }
        >
          <Typography variant="body1">Parent</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {parentFields1.map((field) => (
            <DraggableField key={field.label} field={field} />
          ))}
          <Accordion
            sx={{
              boxShadow: "none",
              ".MuiAccordionSummary-root": {
                minHeight: "fit-content !important",
                padding: "0 !important",
              },
              mt: 1,
            }}
            expanded={accordionState.includes(
              "parent_fields-mailing_address_fields"
            )}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              sx={{
                ".MuiAccordionSummary-content": {
                  margin: "0 !important",
                },
              }}
              onClick={() =>
                setAccordionState(
                  accordionState.includes(
                    "parent_fields-mailing_address_fields"
                  )
                    ? "parent_fields"
                    : "parent_fields-mailing_address_fields"
                )
              }
            >
              <Typography variant="body1">Mailing Address</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {mailingAddressFields.map((field) => (
                <DraggableField key={field.label} field={field} />
              ))}
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{
              boxShadow: "none",
              ".MuiAccordionSummary-root": {
                minHeight: "fit-content !important",
                padding: "0 !important",
              },
              mt: 1,
            }}
            expanded={accordionState.includes(
              "parent_fields-physical_address_fields"
            )}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              sx={{
                ".MuiAccordionSummary-content": {
                  margin: "0 !important",
                },
              }}
              onClick={() =>
                setAccordionState(
                  accordionState.includes(
                    "parent_fields-physical_address_fields"
                  )
                    ? "parent_fields"
                    : "parent_fields-physical_address_fields"
                )
              }
            >
              <Typography variant="body1">Physical Address</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {physicalAddressFields.map((field) => (
                <DraggableField key={field.label} field={field} />
              ))}
            </AccordionDetails>
          </Accordion>
          {parentFields2.map((field) => (
            <DraggableField key={field.label} field={field} />
          ))}

          <Accordion
            sx={{
              boxShadow: "none",
              ".MuiAccordionSummary-root": {
                minHeight: "fit-content !important",
                padding: "0 !important",
              },
              mt: 1,
            }}
            expanded={accordionState.includes(
              "parent_fields-notification_fields"
            )}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              sx={{
                ".MuiAccordionSummary-content": {
                  margin: "0 !important",
                },
              }}
              onClick={() =>
                setAccordionState(
                  accordionState.includes("parent_fields-notification_fields")
                    ? "parent_fields"
                    : "parent_fields-notification_fields"
                )
              }
            >
              <Typography variant="body1">Receive Notifications </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {parentNotificationFields.map((field) => (
                <DraggableField key={field.label} field={field} />
              ))}
            </AccordionDetails>
          </Accordion>
          {parentFields3.map((field) => (
            <DraggableField key={field.label} field={field} />
          ))}
          {customGuardianFields.map((field) => (
            <DraggableField
              key={field.label}
              field={{
                ...field,
                customFieldId: field.id,
                customFieldResponseType: field.response_type,
                field_id: null,
              }}
            />
          ))}
        </AccordionDetails>
      </Accordion> */}
      {/* <Accordion
        sx={{
          boxShadow: "none",
          ".MuiAccordionSummary-root": {
            minHeight: "fit-content !important",
            padding: "0 !important",
          },
          mt: 1,
        }}
        expanded={accordionState.includes("emergency_contact_fields")}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          sx={{
            ".MuiAccordionSummary-content": {
              margin: "0 !important",
            },
          }}
          onClick={() =>
            setAccordionState(
              accordionState.includes("emergency_contact_fields")
                ? ""
                : "emergency_contact_fields"
            )
          }
        >
          <Typography variant="body1">Emergency Contact</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {emergencyContactFields1.map((field) => (
            <DraggableField key={field.label} field={field} />
          ))}
          <Accordion
            sx={{
              boxShadow: "none",
              ".MuiAccordionSummary-root": {
                minHeight: "fit-content !important",
                padding: "0 !important",
              },
              mt: 1,
            }}
            expanded={accordionState.includes(
              "emergency_contact_fields-notification_fields"
            )}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              sx={{
                ".MuiAccordionSummary-content": {
                  margin: "0 !important",
                },
              }}
              onClick={() =>
                setAccordionState(
                  accordionState.includes(
                    "emergency_contact_fields-notification_fields"
                  )
                    ? "emergency_contact_fields"
                    : "emergency_contact_fields-notification_fields"
                )
              }
            >
              <Typography variant="body1">Receive Notifications </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {emergencyContactReceiveNotifications.map((field) => (
                <DraggableField key={field.label} field={field} />
              ))}
            </AccordionDetails>
          </Accordion>

          {emergencyContactFields2.map((field) => (
            <DraggableField key={field.label} field={field} />
          ))}
        </AccordionDetails>
      </Accordion> */}
    </Box>
  );
}

function DraggableField({ field }) {
  const formBuilderContext = useContext(FormBuilderContext);
  return (
    <Grid
      container
      draggable
      unselectable="on"
      onDragStart={(e) => {
        formBuilderContext.fireOnDragStart({ type: "form_field" });
        e.dataTransfer.setData(
          "text/plain",
          JSON.stringify({
            ...field,
            name: field.identifier,
            type: field.field_type || field.type,
            id: null,
          })
        );
      }}
      onDragEnd={() => {
        formBuilderContext.fireOnDragStop();
      }}
      sx={fieldStyle}
    >
      <Grid item>
        <Typography variant="body2">{field.label}</Typography>
      </Grid>
      <Grid item>
        <ArrowRightAlt fontSize="small" />
        <Segment fontSize="small" />
      </Grid>
    </Grid>
  );
}
