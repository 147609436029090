/* eslint-disable no-param-reassign */
import { Box, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { renderError } from "../../../../../utils/constants/forms";

export default function ShortTextRenderer({ field, properties, dataView }) {
  const {
    register,
    formState: { errors, isSubmitted },
    trigger,
    setValue,
  } = useFormContext();

  useEffect(() => {
    if (isSubmitted) {
      setValue(field.fieldId, field.response || "", { shouldDirty: true });
      trigger(field.fieldId);
    }
  }, [field.isValid]);

  return (
    <Box padding={1}>
      <TextField
        fullWidth
        error={!!errors[field.fieldId]}
        label={field.label}
        key={field.fieldId}
        defaultValue={field.response || field.existing_data || ""}
        InputLabelProps={{
          required: properties.isRequired,
          shrink: properties.shrink || undefined,
        }}
        helperText={properties.supportingText}
        InputProps={{ readOnly: dataView }}
        {...register(field.fieldId, {
          onChange: (e) => {
            field.response = e.target.value;
          },
          required: properties.isRequired && "This field is required.",
          minLength: {
            value: properties.minLength || null,
            message: `${field.label} must be at least ${properties.minLength} characters long`,
          },
          maxLength: {
            value: properties.maxLength || null,
            message: `${field.label} must not exceed ${properties.maxLength} characters`,
          },
          validate: field.isValid
            ? () => true
            : () => properties.validityMessage || "Invalid input",
        })}
      />
      {errors[field.fieldId] && renderError(errors[field.fieldId].message)}
    </Box>
  );
}
