import React, { useContext } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { containerStyle } from "../FormBuilder/FormBuilder.style";
import formRenderMap from "./formRenderMap";
import { FormBuilderContext } from "../FormBuilder/FormBuilderContext";
import { formTitle } from "../../../sharedStyles";

export default function FormSectionRenderer({
  section,
  sectionIndex,
  formattedView = false,
  dataView,
  guardianView,
}) {
  const formBuilderContext = useContext(FormBuilderContext);
  const { form } = formBuilderContext;

  const setCurrentSection = () => {
    formBuilderContext.updateCurrentSectionIndex(sectionIndex);
  };

  const studentLabelToDbFieldMap = {
    "Full Legal Name": "legal_name",
    "First Name": "first_name",
    "Middle Name": "middle_name",
    "Last Name": "last_name",
    "Preferred Name": "preferred_name",
    Birthdate: "date_of_birth",
    "Place of Birth": "place_of_birth",
    Gender: "gender",
    Ethnicity: "ethnicity",
    Language: "language",
    Denomination: "denomination",
    "Home Church": "home_church",
    "Primary Email": "primary_email",
    "Secondary Email": "secondary_email",
    "Student Mobile Number": "phone_number",
    "Doctor Name": "doctor_name",
    "Clinic Name": "doctor_hospital_name",
    "Phone Number": "doctor_phone_number",
    "Dentist Name": "dentist_name",
    "Insurance company Name": "insurance_company_name",
    "Policy Number": "insurance_policy_number",
    "Baptism Date": "baptism_year",
  };

  const parentLabelToDbFieldMap = {
    "First Name": "first_name",
    "Middle Name": "middle_name",
    "Last Name": "last_name",
    "Cell Phone": "cell_phone",
    "Secondary Phone": "secondary_phone",
    Email: "email",
    "Home Church": "home_church",
    "Is Adventist": "is_adventist",
    "Primary Contact": "is_primary_contact",
    "Approved Volunteer": "is_approved_volunteer",
    "Emergency Contact": "is_emergency_contact",
    "Custodial Parent": "is_custodial_parent",
    "Authorized Pickup": "authorized_pickup",
    "Vehicle License": "authorized_pickup_vehicle_license",
    "School Transportation": "school_transportation",
    Text: "text_notifications",
    Notes: "notes",
    "Relationship to the student": "relationship",
    "Address 1": "mailing_address_line1",
    "Address 2": "mailing_address_line2",
    City: "mailing_address_city",
    State: "mailing_address_state",
    "Postal Code": "mailing_address_postal_code",
    Country: "mailing_address_country",
  };

  const getDbField = (label, isParent = false) => {
    if (isParent) {
      return parentLabelToDbFieldMap[label] || null;
    }
    return studentLabelToDbFieldMap[label] || null;
  };

  return (
    <Box
      onClick={setCurrentSection}
      container
      sx={containerStyle(formattedView)}
    >
      <Grid mb={2} container justifyContent="space-between">
        <Typography color="black" sx={formattedView ? formTitle : ""}>
          {section.name}
        </Typography>
      </Grid>
      <Grid
        container
        columnSpacing={2}
        rowGap={formattedView ? 0 : 2}
        alignItems="center"
      >
        {section.fields.map((field) => {
          if (!field.isVisible) {
            return null;
          }
          const Component = formRenderMap[field.type];
          const dbField = getDbField(field.label, section.isParentInfo);
          let existingData = null;
          if (dbField && !section.isParentInfo) {
            existingData = form.existingStudentData[dbField] || null;
          } else if (dbField && section.isParentInfo) {
            existingData = form.existingParentData[dbField] || null;
          }

          return (
            <Grid
              key={field.fieldId}
              item
              md={field.w}
              sx={{
                position: "relative",
                height: "fit-content",
                // display: field.isVisible ? "" : "none",
              }}
            >
              <Component
                field={{
                  ...field,
                  existing_data: guardianView ? existingData : null,
                }}
                properties={{ ...field.properties, shrink: formattedView }}
                dataView={dataView}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
