import { Grid, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";

export default function LabelComponent({ selectedField }) {
  const { setValue, control } = useFormContext();

  useEffect(() => {
    setValue("label", selectedField.label);
  }, [selectedField]);

  return (
    <Grid item md={12}>
      <Controller
        name="label"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            disabled={
              selectedField.source_field_id || !selectedField.isCustomField
            }
            label="Help Text"
          />
        )}
      />
    </Grid>
  );
}
