/* eslint-disable no-param-reassign */
import { Box, TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import moment from "moment";
import { renderError } from "../../../../../utils/constants/forms";
import dateTimeFormats from "../../../../../utils/constants/dateTimeFormats";

export default function DateFieldRenderer({ field, properties, dataView }) {
  const [dateValue, setDateValue] = useState(
    (field?.response &&
      moment(field?.response).utc().format(dateTimeFormats.MMDDYYYY)) ||
      (field.existing_data &&
        moment(field.existing_data).utc().format(dateTimeFormats.MMDDYYYY)) ||
      ""
  );
  const {
    formState: { errors, isSubmitted },
    setValue,
    register,
    trigger,
  } = useFormContext();

  const onDateChanged = (date) => {
    if (date) {
      setDateValue(date);
      setValue(field.fieldId, date.format("MM/DD/YYYY"), {
        shouldDirty: true,
      });
      setTimeout(() => {
        trigger(field.fieldId);
      }, 100);
    }
    field.response = date;
  };

  useEffect(() => {
    if (isSubmitted) trigger(field.fieldId);
  }, [field.isValid]);

  return (
    <Box padding={1}>
      <DesktopDatePicker
        label={field.label}
        inputFormat="MM/DD/YYYY"
        onChange={onDateChanged}
        value={dateValue}
        readOnly={dataView}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{ width: "100%" }}
            required={properties.isRequired}
            InputLabelProps={{ shink: properties.shrink || undefined }}
            {...register(field.fieldId, {
              required: properties.isRequired && "This field is required.",
              pattern: {
                value: /(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/(19|20)\d{2}/,
                message: "Invalid date.",
              },
              validate: field.isValid
                ? () => true
                : () => properties.validityMessage || "Invalid input",
            })}
            error={!!errors[field.fieldId]}
          />
        )}
      />
      {errors[field.fieldId] && renderError(errors[field.fieldId].message)}
    </Box>
  );
}
