/* eslint-disable no-param-reassign */
import { Box, Checkbox, FormControlLabel, FormHelperText } from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { renderError } from "../../../../../utils/constants/forms";

export default function CheckboxRenderer({ field, properties, dataView }) {
  const {
    control,
    register,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    setValue(field.fieldId, !!field.response, {
      shouldDirty: true,
      shouldTouch: true,
    });
  }, []);

  return (
    <Box padding={1}>
      <Controller
        name={field.fieldId}
        control={control}
        {...register(field.fieldId, {
          validate: field.isValid
            ? () => true
            : () => properties.validityMessage || "Invalid input",
        })}
        defaultValue={false} // or true, depending on what you need
        render={({ checkboxField }) => (
          <>
            <FormControlLabel
              sx={{
                ".MuiButtonBase-root": { color: "#22282C !important" },
                ".MuiTypography-root": { color: "#3F464B !important" },
              }}
              control={
                <Checkbox
                  disabled={dataView}
                  onChange={(event, value) => {
                    setValue(field.fieldId, value, { shouldDirty: true });
                    field.response = value;
                    setTimeout(() => {
                      trigger(field.fieldId);
                    }, 100);
                  }}
                  {...checkboxField}
                  checked={getValues(field.fieldId)}
                  defaultChecked={field.response || field.existing_data}
                />
              }
              label={field.label}
            />
            <FormHelperText>{properties.supportingText}</FormHelperText>
          </>
        )}
      />
      {errors[field.fieldId] && renderError(errors[field.fieldId].message)}
    </Box>
  );
}
